import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import { get } from 'lodash';
import maIntegrationKit from './services/ma-integration-kit';
import { blogAppDefId } from './constants/apps';
import monitoring from './services/monitoring';
import handlers from './services/handlers';
import { RAVEN_INIT_STRING, FEDOPS_APP_NAME } from './constants/monitoring';
import { getMetaSiteId } from './services/instance';
import { getAssetsUrl } from './services/editor-sdk';

export const createEditorApp = () => {
  let context = {};

  const editorReady = (sdk, appToken, options) => {
    // eslint-disable-next-line
    console.log('blog platform app is up');
    process.env.NODE_ENV === 'development' && console.log('options:', options);

    const instance = get(options, 'initialAppData.instance');
    const instanceId = get(options, 'initialAppData.instanceId');
    const metaSiteId = getMetaSiteId(instance);
    const origin = get(options, 'origin.type');

    monitoring.init(FEDOPS_APP_NAME, RAVEN_INIT_STRING, { metaSiteId, instanceId });
    context = {
      sdk,
      appToken,
      isADI: origin === 'ADI' || origin === 'ADI_MA',
      isClassicEditor: origin === 'CLASSIC',
      installMembersArea: get(options, 'origin.info.installMembersArea'),
      instance,
      assetsUrl: getAssetsUrl(options),
      biData: options.biData,
    };

    return monitoring.toMonitored('editor-ready', handlers.handleEditorReady(context));
  };

  const handleAction = async ({ type, payload }) => {
    try {
      switch (type) {
        case 'appInstalled': {
          switch (payload.appDefinitionId) {
            case blogAppDefId:
              return monitoring.toMonitored('handle-blog-installed', handlers.handleBlogInstalled(context));
            default:
              return Promise.resolve();
          }
        }
        case 'migrate':
          return handlers.handleMigrateAction(context, payload);
        default:
          return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onEvent = (event) => handlers.handleOnEvent(event, context);
  const getAppManifest = () => Promise.resolve(handlers.handleGetAppManifest(context));
  const getControllerPresets = () => Promise.resolve([]);

  return { editorReady, handleAction, onEvent, getAppManifest, getControllerPresets };
};

const editorApp = maIntegrationKit.withMembersArea(createEditorApp(), {
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.NOTIFICATIONS, MA_APP_IDS.SETTINGS],
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const onEvent = editorApp.onEvent;
export const getAppManifest = editorApp.getAppManifest;
export const getControllerPresets = editorApp.getControllerPresets;
