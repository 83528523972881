import { map, get, partial } from 'lodash';
import { TinyColor } from '@ctrl/tinycolor';
import { getComponentRef } from './magic-migration/sdk-utils';
import { BLOG_WIDGET_ID } from '../constants/widgets';
import {
  BUTTON_COLOR,
  CARD_BACKGROUND_COLOR,
  CARD_BORDER_COLOR,
  IS_FEED_DESIGN_PROPS_MIGRATED,
  LAYOUT_SETTINGS_PROPS,
  LINK_AND_HASHTAG_COLOR,
  POST_DESCRIPTION_FONT,
  POST_TEXT_COLOR,
  POST_TITLE_FONT,
  NAVIGATION_TEXT_COLOR_ACTIVE,
  FEED_PAGE_SECTIONS,
  LAYOUT_TYPES,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_PG_GRID,
  getCardBorderWidth,
  getPostLayoutParamByName,
  isLayoutTextOnImage,
  getLayoutName,
  getLayoutType,
} from '@wix/communities-blog-client-common';
import { getColorColorNameByReference } from './magic-migration/style-utils';

export const migrateFeedDesignProps = async ({ sdk, appToken, isClassicEditor }) => {
  const feedCompRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
  if (!feedCompRef) {
    return;
  }

  const styleParams = await sdk.document.tpa.getStyleParams(appToken, { compRef: feedCompRef });

  if (isFeedDesingPropsMigrated(styleParams)) {
    return;
  }

  const migratedStyleParams = getMigratedStyleParams({ styleParams, isClassicEditor });
  await sdk.document.tpa.setStyleParams(appToken, { compRef: feedCompRef, styleParams: migratedStyleParams });
};

function isFeedDesingPropsMigrated(styleParams) {
  return Boolean(get(styleParams, `booleans.${IS_FEED_DESIGN_PROPS_MIGRATED}`));
}

export function getMigratedStyleParams({ styleParams, isClassicEditor = true } = {}) {
  const updates = [];
  const updateValue = (type) => (key, value) => value && updates.push({ type, key, param: { value } });
  const updateFont = updateValue('font');
  const updateNumber = updateValue('number');
  const updateBoolean = updateValue('boolean');
  const updateColor = updateValue('color');

  const titleFont = get(styleParams, `fonts.${POST_TITLE_FONT}`);
  const descriptionFont = get(styleParams, `fonts.${POST_DESCRIPTION_FONT}`);
  const linkHashtagColor = get(styleParams, `colors.${LINK_AND_HASHTAG_COLOR}`, { themeName: 'color-8' });
  const borderColor = get(styleParams, `colors.${CARD_BORDER_COLOR}`, { themeName: 'color-2', opacity: '0.75' });
  const cardBackgroundColor = get(styleParams, `colors.${CARD_BACKGROUND_COLOR}`);
  const postTextColor = get(styleParams, `colors.${POST_TEXT_COLOR}`);
  const buttonColor = get(styleParams, `colors.${BUTTON_COLOR}`);

  const getFontColor = (layoutType, param) =>
    isLayoutTextOnImage(layoutType) || !postTextColor
      ? { themeName: get(LAYOUT_SETTINGS_PROPS, `${layoutType}.${param}.startWithColor`) }
      : postTextColor;

  const getFontSize = (section, layoutType, key) => {
    const layoutName = getLayoutName(layoutType);
    const styleParamKey = `numbers.${getPostLayoutParamByName(layoutName, section, key)}FontSize`;
    return get(styleParams, styleParamKey, get(LAYOUT_SETTINGS_PROPS, `${layoutType}.${key}Font.startWithSize`));
  };

  const setColor = (key, color) => {
    const tinyColor = new TinyColor(color.value);
    if (color.value && tinyColor.isValid) {
      updateColor(key, { rgba: tinyColor.toRgbString(), opacity: tinyColor.getAlpha() });
    } else {
      const opacity = Number(color.opacity);
      const name = getColorColorNameByReference(color.themeName);
      name &&
        updateColor(key, {
          color: { name },
          ...(Number.isFinite(opacity) ? { opacity } : {}),
        });
    }
  };

  if (buttonColor && new TinyColor(buttonColor.value).isValid) {
    setColor(BUTTON_COLOR, {
      ...buttonColor,
      value: new TinyColor(buttonColor.value).setAlpha(1).toRgbString(),
    });
  }

  setColor(NAVIGATION_TEXT_COLOR_ACTIVE, linkHashtagColor);

  map(FEED_PAGE_SECTIONS, (section) => {
    const sectionLayoutType = getSectionLayoutType({ styleParams, section, isClassicEditor });
    const getGenericParam = partial(getPostLayoutParamByName, section);

    map(LAYOUT_TYPES, (layoutName, layoutType) => {
      const getParam = partial(getPostLayoutParamByName, layoutName, section);
      const getFontSizeByKey = partial(getFontSize, section, layoutType);

      if (titleFont) {
        const layoutTitleFont = getFontValue(titleFont, getFontSizeByKey('title'));
        updateFont(getParam('titleFont'), layoutTitleFont);

        if (parseInt(layoutType) === sectionLayoutType) {
          updateFont(getGenericParam('titleFont'), layoutTitleFont);
        }
      }

      if (descriptionFont) {
        const layoutDescriptionFont = getFontValue(descriptionFont, getFontSizeByKey('description'));
        updateFont(getParam('descriptionFont'), layoutDescriptionFont);

        if (parseInt(layoutType) === sectionLayoutType) {
          updateFont(getGenericParam('descriptionFont'), layoutDescriptionFont);
        }
      }
    });

    updateNumber(
      getGenericParam('borderWidth'),
      get(styleParams, `numbers.${getCardBorderWidth(sectionLayoutType, section)}`, 1),
    );

    setColor(getGenericParam('titleColor'), getFontColor(sectionLayoutType, 'titleFont'));
    setColor(getGenericParam('descriptionColor'), getFontColor(sectionLayoutType, 'descriptionFont'));
    setColor(getGenericParam('borderColor'), borderColor);
    setColor(getGenericParam('linkHashtagColor'), linkHashtagColor);
    setColor(getGenericParam('backgroundColor'), cardBackgroundColor || { themeName: 'color-1' });
    setColor(getGenericParam('TOI', 'backgroundColor'), cardBackgroundColor || { themeName: 'color-5' });
  });

  updateBoolean(IS_FEED_DESIGN_PROPS_MIGRATED, true);

  return updates;
}

function getSectionLayoutType({ section, styleParams, isClassicEditor }) {
  const defaultLayout = isClassicEditor ? LAYOUT_SIDE_BY_SIDE : LAYOUT_PG_GRID;
  return get(styleParams, `numbers.${getLayoutType(section)}`, defaultLayout);
}

function getFontValue(font, size) {
  return {
    ...font,
    size,
    preset: 'Custom',
  };
}
