import { EXPERIMENT_INSTALL_NEW_MEMBER_PAGES } from '@wix/communities-blog-experiments';
import experiments from '../experiments';
import maIntegrationKit from '../ma-integration-kit';

const MEMBER_PAGES = [
  maIntegrationKit.MA_APP_IDS.BLOG_POSTS,
  maIntegrationKit.MA_APP_IDS.BLOG_COMMENTS,
  maIntegrationKit.MA_APP_IDS.BLOG_LIKES,
  maIntegrationKit.MA_APP_IDS.MY_DRAFTS,
];

const NEW_MEMBER_PAGES = [
  // TODO: add new pages
];

export async function registerMemberPages({ sdk, isADI }) {
  if (!experiments.isEnabled(EXPERIMENT_INSTALL_NEW_MEMBER_PAGES)) {
    return await maIntegrationKit.registerMembersAreaApps(MEMBER_PAGES);
  }

  const hasMemberPage = await hasAnyMemberPage({ sdk });
  if (hasMemberPage || isADI) {
    return await maIntegrationKit.registerMembersAreaApps(MEMBER_PAGES);
  } else {
    return await maIntegrationKit.registerMembersAreaApps(NEW_MEMBER_PAGES);
  }
}

async function hasAnyMemberPage() {
  const installations = await Promise.all(
    MEMBER_PAGES.map((memberPage) => maIntegrationKit.isMembersAreaAppInstalled(memberPage)),
  );

  return installations.filter(Boolean).length > 0;
}
